.avatar {
  border-radius: 100px;
  background-size: cover;
  user-select: none;
  margin-bottom: 5px;
}

.letterPlaceholder {
  width: 100%;
  height: 100%;
}

.letterPlaceholder text {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  fill: white;
}

.avatarContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
}